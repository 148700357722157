import { useMutation } from '@apollo/client';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import XAlertMessage from '../../AlertMessage/XAlertMessage';
import { BUY_CREDITS } from '../../../../GraphApi/VerifyContactEmail';
import { getValidationMessage } from '../../../../Utils/utils';
import { BuyCreditsForm } from './BuyCreditsForm';
import { EqupRoundLoaderWithOverlay } from '../../Loader/RoundLoader';

export const BuyMoreCredits = ({
  locale,
  handleClose,
  team_id,
  currentCompanySetting,
  latestAvailableCredits = 0,
  manageCredit = null,
  viaPage = '',
  setResetCreditHistory = () => {},
}) => {
  const refDrawer = useRef(null);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  /*****/
  const [creditToBuy, setCreditToBuy] = useState(null);
  const [noOfCredits, setNoOfCredits] = useState(null);
  const [autoRefillCredits, setAutoRefillCredits] = useState(false);
  const [autoBuyCreditsCount, setAutoBuyCreditsCount] = useState(100);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buyCallingCredits] = useMutation(BUY_CREDITS);
  /** This Methods use in Formik Only ***/
  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
  };

  useEffect(() => {
    if (manageCredit) {
      setAutoRefillCredits(true);
      setAutoBuyCreditsCount(manageCredit.auto_buy_credits_count);
    } else {
      setAutoRefillCredits(false);
    }
  }, [manageCredit]);

  const buyCreditsFunction = () => {
    setIsSubmitting(true);
    buyCallingCredits({
      variables: {
        teamId: team_id,
        creditQuantity: parseInt(noOfCredits),
        creditPrice: parseFloat(creditToBuy),
        autoRefillCredits: autoRefillCredits,
        autoBuyCreditsCount: autoBuyCreditsCount
          ? parseInt(autoBuyCreditsCount)
          : autoBuyCreditsCount,
      },
    })
      .then((result) => {
        handleAlertMessage(
          'success',
          locale('messages:common.purchased', {
            fieldName: noOfCredits + ' ' + locale('credits'),
          })
        );
        if (viaPage !== 'creditHistory') {
          handleClose();
        }
        setNoOfCredits(null);
        setCreditToBuy(null);
        setResetCreditHistory(true);
        // handleParentAlertMessage();
        setIsSubmitting(false);
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage('error', getValidationMessage(graphQLErrors));
      });
  };

  return (
    <>
      <Modal.Header>
        <Button
          variant={'link'}
          className={'close'}
          onClick={handleClose}
        >
          <i className={'ri-arrow-left-s-line'}></i> {locale('back')}
        </Button>
      </Modal.Header>
      <Modal.Body ref={refDrawer}>
        <XAlertMessage
          type={alertType}
          message={alertMessage}
          setAlertShow={setAlertShow}
          alertShow={alertShow}
        />
        <h3>{locale('pages:setting.credits.Buy Credits')}</h3>
        <div className='mt-5 setting-main-wrapper'>
          {isSubmitting && <EqupRoundLoaderWithOverlay />}
          <BuyCreditsForm
            team_id={team_id}
            locale={locale}
            creditToBuy={creditToBuy}
            setCreditToBuy={setCreditToBuy}
            setNoOfCredits={setNoOfCredits}
            isSubmitting={isSubmitting}
            latestAvailableCredits={latestAvailableCredits}
            handleAlertMessage={handleAlertMessage}
            buyCreditsFunction={buyCreditsFunction}
          />
        </div>
      </Modal.Body>
    </>
  );
};
