import React, { useState } from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const getDerivedStateFromError = () => {
    // Update state to render fallback UI
    setHasError(true);
  };

  const componentDidCatch = (error, errorInfo) => {
    // You can log the error to an error reporting service
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);
  };

  if (hasError) {
    return (
      <Container className="text-center" style={{ paddingTop: "50px" }}>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="shadow-lg p-4">
              <Card.Body>
                <Card.Title>
                  <h1 className="text-danger">Oops! Something Went Wrong.</h1>
                </Card.Title>
                <Card.Text className="mb-4">
                  We encountered an error while loading this page. You can try
                  the options below to resolve the issue.
                </Card.Text>
                <div className="d-flex justify-content-center">
                  <Button variant="primary" className="me-3" onClick={goBack}>
                    Go Back
                  </Button>
                  <Button
                    variant="secondary"
                    className="me-3"
                    onClick={goToDashboard}
                  >
                    Go to Dashboard
                  </Button>
                  <Button variant="danger" onClick={reloadPage}>
                    Reload Page
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return children;
};

export default ErrorBoundary;
