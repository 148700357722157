import React from 'react';

import { ApolloProvider } from '@apollo/client';

import AuthContextProvider from './AuthContext.js';
import graphqlClient from '../RestApi/GraphApi';
import { InventoryConsumptionProvider } from './InventoryConsumption.js';
import { TrialPlanCheckerProvider } from './TrialPlanChecker.js';
import { ModulePermissionProvider } from './ModulePermission.js';

const MainContext = ({ children }) => {
  return (
    <div>
      <ApolloProvider client={graphqlClient}>
        <AuthContextProvider>
          <ModulePermissionProvider>
            <TrialPlanCheckerProvider>
              <InventoryConsumptionProvider>
                {children}
              </InventoryConsumptionProvider>
            </TrialPlanCheckerProvider>
          </ModulePermissionProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </div>
  );
};

export default MainContext;
