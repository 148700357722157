import React, { useEffect, useState } from 'react';

import { Device } from '@twilio/voice-sdk';
import { getCallToken } from '../../../RestApi/RestApi';
import { Container } from 'react-bootstrap';
import { timeConvert } from '../../../Utils/utils';
import { useRecoilState } from 'recoil';
import { CallLogState } from '../../../Atoms/ContactDetail/CallLogAtom';

export const millisecondsInSeconds = 1000;

export const CallButton = ({ onPhone, disabled, handleOnClick }) => {
  return (
    <button
      className={'btn btn-circle ' + (onPhone ? 'btn-danger' : 'btn-success')}
      onClick={handleOnClick}
      disabled={disabled}
    >
      <i className={onPhone ? 'ri-phone-fill' : 'ri-phone-line'}></i>
    </button>
  );
};

export const LogBox = ({ text }) => {
  return (
    <div>
      <div className='log'>{text}</div>
    </div>
  );
};

export const DialerApp = ({
  teamId,
  toNumber,
  setToNumber,
  handleAlertMessage,
}) => {
  const [log, setLog] = useState('');
  const [onPhone, setOnPhone] = useState(false);
  const [muted, setMuted] = useState(false);
  const [device, setDevice] = useState(null);
  const [callObj, setCallObj] = useState(null);
  const [showCallingBar, setShowCallingBar] = useState(false);
  const [message, setMessage] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [timeOnCall, setTimeOnCall] = useState('00:00:00');
  const [intervalObj, setIntervalObj] = useState(null);

  const [setCallLog] = useRecoilState(CallLogState);

  // Initialize after component creation
  useEffect(() => {
    if (device === null) {
      const dataToPost = {
        team_id: teamId,
      };
      // Fetch Twilio capability token from our server
      getCallToken(dataToPost)
        .then((data) => {
          if (data && data.data && data.data.token) {
            const deviceObj = new Device(data.data.token);
            setDevice(deviceObj);

            setupHandlers(deviceObj);
            deviceObj.register();
          } else if (data && data.data && data.data.message) {
            setMessage(data.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLog('Not able to establish connection to calling server.');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Make an outbound call with the current number,
  // or hang up the current call
  const handleToggleCall = async () => {
    // console.log("I am inside handle toggle call", onPhone);
    if (!onPhone) {
      setMuted(false);
      setOnPhone(true);
      // make outbound call with current number
      const n = toNumber;
      const call = await device.connect({ params: { To: n } });
      setStartTime(new Date().getTime() / millisecondsInSeconds);
      setCallObj(call);
      callSetupHandlers(call);
      setLog('Calling ' + n);
    } else {
      // hang up call in progress
      device.disconnectAll();
      setStartTime(null);
      setCallObj(null);
      setTimeOnCall('00:00:00');
      setOnPhone(false);
      setShowCallingBar(false);
      setToNumber(false);
      setCallLog(true);
      clearInterval(intervalObj);
      setIntervalObj(null);
    }
  };

  const callSetupHandlers = (call) => {
    call.on('reconnecting', (twilioError) => {
      // update the UI to alert user that connectivity was lost
      // and that the SDK is trying to reconnect
      // showReconnectingMessageInBrowser();
      console.log('trying to re-connect');
      // You may also want to view the TwilioError:
      console.log('Connectivity error: ', twilioError);
    });

    call.on('disconnect', (connection) => {
      console.log('disconnected from mobile: ', connection);
      device.disconnectAll();
      setStartTime(null);
      setCallObj(null);
      setTimeOnCall('00:00:00');
      setOnPhone(false);
      setShowCallingBar(false);
      setToNumber(false);
      setCallLog(true);
      clearInterval(intervalObj);
      setIntervalObj(null);
    });
  };

  useEffect(() => {
    if (toNumber) {
      if (device && device.token) {
        setShowCallingBar(true);
        handleToggleCall();
      } else {
        handleAlertMessage(
          'error',
          message
            ? message
            : 'Something went wrong. Please refresh page and try again.'
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toNumber]);

  const setupHandlers = (device) => {
    device.on('error', (twilioError, call) => {
      console.log('An error has occurred: ', twilioError);
      console.log(
        twilioError.causes,
        twilioError.code,
        twilioError.description,
        twilioError.explanation,
        twilioError.message,
        twilioError.name,
        twilioError.originalError,
        twilioError.solutions
      );
    });
    device.on('registered', () => {
      console.log('The device is ready to receive incoming calls.');
    });
  };
  // Handle muting
  const handleToggleMute = () => {
    const newMuted = !muted;
    setMuted(newMuted);
    callObj.mute(newMuted);
  };

  useEffect(() => {
    if (onPhone) {
      const staticStartTime = new Date().getTime() / millisecondsInSeconds;
      const localIntervalObj = setInterval(() => {
        calculateTimeOnCall(staticStartTime);
      }, 1000);
      setIntervalObj(localIntervalObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPhone]);

  const calculateTimeOnCall = (staticStartTime = null) => {
    const actualStartTime =
      staticStartTime === null ? startTime : staticStartTime;
    setTimeOnCall(
      timeConvert(
        parseInt(new Date().getTime() / millisecondsInSeconds - actualStartTime)
      )
    );
  };

  if (showCallingBar)
    return (
      <div
        id='dialer'
        className='calling-bar'
      >
        <Container fluid>
          <div className='d-flex align-items-center justify-content-between'>
            <LogBox text={log} />
            <div>{timeOnCall}</div>
            <div className='controls'>
              {onPhone ? (
                <button
                  className='btn btn-circle btn-warning'
                  onClick={handleToggleMute}
                >
                  <i className={muted ? 'ri-mic-off-fill' : 'ri-mic-fill'}></i>
                </button>
              ) : null}
              <CallButton
                handleOnClick={handleToggleCall}
                disabled={false}
                onPhone={onPhone}
              />
            </div>
          </div>
        </Container>
      </div>
    );
  else return <></>;
};
