import React from "react";
import "./SkeletonLoader.scss";
import { Row, Col, ListGroup } from "react-bootstrap";

export const SkeletonLoader = () => {
  return (
    <div className="card_main border p-3 mt-3 mb-3">
      <div className="card__image loading m-auto"></div>
      <div className="card__title loading w-60 p-3 ms-auto me-auto"></div>
      <LineLoader className={"loading p-2"} />

      <div className="social-media-loader loader text-center">
        <SocialMediaLoader className={"inline-block round-circle mt-3 m-1"} />
        <SocialMediaLoader className={"inline-block round-circle m-1"} />
        <SocialMediaLoader className={"inline-block round-circle m-1"} />
        <SocialMediaLoader className={"inline-block round-circle m-1"} />
      </div>
      <div className="lead-score-loader flex-justify loader">
        <div className="card__address loading w-30"></div>
        <div className="card__address loading w-30"></div>
        <div className="card__address loading w-30"></div>
      </div>

      <div className="text-center loader mb-3">
        <LineLoader />
        <LineLoader />
        <LineLoader />
        <LineLoader />
      </div>
    </div>
  );
};

export const ImageLoader = () => {
  return (
    <div className="card_main">
      <div className="card__image loading"></div>
    </div>
  );
};

export const DescriptionLoader = () => {
  return (
    <div className="card_main">
      <div className="card__title loading"></div>
      <div className="card__description loading"></div>
    </div>
  );
};

export const SingleLineLoader = () => {
  return (
    <div className="card_main">
      <div className={"card__title loading"}></div>
    </div>
  );
};

export const LineLoader = ({
  className = "",
  height = "10px",
  width = "auto",
  marginTop = "1rem",
}) => {
  return (
    <div
      className={"card__title loading " + className}
      style={{ width: width, height: height, marginTop: marginTop }}
    ></div>
  );
};

export const RatioLoader = ({
  className = "",
  height = "10px",
  width = "auto",
}) => {
  return (
    <div
      className={"card__title loading " + className}
      style={{ width: width, height: height }}
    >
      <div
        className={"round-circle"}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
        }}
      ></div>
    </div>
  );
};

export const SocialMediaLoader = ({
  className = "",
  height = "40px",
  width = "40px",
}) => {
  return (
    <div
      className={"card__title loading " + className}
      style={{ width: width, height: height }}
    ></div>
  );
};
export const ListLoader = () => {
  return (
    <div className="card_main">
      <div className="card__title loading"></div>
      <div className="card__title loading"></div>
      <div className="card__title loading"></div>
      <div className="card__title loading"></div>
    </div>
  );
};

export const ProfileLoader = () => {
  return (
    <div className="card_main">
      <div className="card-profile-image loading"></div>
      <div className="profile-card-heading loading"></div>
    </div>
  );
};

export const TableLoader = () => {
  return (
    <div className="card_main border mt-3 mb-3">
      <LineLoader height="50px" className={"m-3"} />
      <div className="flex-justify ps-3 pe-3">
        <LineLoader className={"w-30"} />
        <LineLoader className={"w-30"} />
        <LineLoader className={"w-30"} />
      </div>
      <div className="flex-justify ps-3 pe-3 mb-3">
        <LineLoader className={"w-30"} />
        <LineLoader className={"w-30"} />
        <LineLoader className={"w-30"} />
      </div>
      <div className="flex-justify ps-3 pe-3 mb-3">
        <LineLoader className={"w-30"} />
        <LineLoader className={"w-30"} />
        <LineLoader className={"w-30"} />
      </div>
    </div>
  );
};

export const DealValueLoader = () => {
  return (
    <div className="card_main border mt-3 mb-3 p-3">
      <LineLoader height="20px" />

      <div className="flex-justify ">
        <div className="social-media-loader loader text-center w-18">
          <LineLoader
            width="50px"
            height="50px"
            className={"inline-block round-circle"}
          />
        </div>
        <div className="social-media-loader loader text-center w-18">
          <LineLoader
            width="50px"
            height="50px"
            className={"inline-block round-circle"}
          />
        </div>
        <div className="social-media-loader loader text-center w-18">
          <LineLoader
            width="50px"
            height="50px"
            className={"inline-block round-circle"}
          />
        </div>
        <div className="social-media-loader loader text-center w-18">
          <LineLoader
            width="50px"
            height="50px"
            className={"inline-block round-circle"}
          />
        </div>
        <div className="social-media-loader loader text-center w-18">
          <LineLoader
            width="50px"
            height="50px"
            className={"inline-block round-circle"}
          />
        </div>
      </div>
      <div className="flex-justify ">
        <LineLoader width="18%" height="8px" />
        <LineLoader width="18%" height="8px" />
        <LineLoader width="18%" height="8px" />
        <LineLoader width="18%" height="8px" />
        <LineLoader width="18%" height="8px" />
      </div>
    </div>
  );
};

export const RecentInteractionLoader = () => {
  return (
    <div className="card_main border mt-3 mb-3 p-3">
      <LineLoader height="20px" className={"mb-3"} />
      <div className={"flex-justify mb-2"}>
        <LineLoader
          height="30px"
          width="30px"
          className={"inline-block me-1 mt-0"}
        />
        <div className="w-50 inline-block me-1 v-top">
          <LineLoader height="18px" width="100%" className={"mt-0"} />
        </div>
        <LineLoader height="12px" width="10%" className={"inline-block mt-0"} />
      </div>
      <div className={"flex-justify mb-2"}>
        <LineLoader
          height="30px"
          width="30px"
          className={"inline-block me-1 mt-0"}
        />
        <div className="w-50 inline-block me-1 v-top">
          <LineLoader height="18px" width="100%" className={"mt-0"} />
        </div>
        <LineLoader height="12px" width="10%" className={"inline-block mt-0"} />
      </div>

      <div className={"flex-justify mb-2"}>
        <LineLoader
          height="30px"
          width="30px"
          className={"inline-block me-1 mt-0"}
        />
        <div className="w-50 inline-block me-1 v-top">
          <LineLoader height="18px" width="100%" className={"mt-0"} />
        </div>
        <LineLoader height="12px" width="10%" className={"inline-block mt-0"} />
      </div>
    </div>
  );
};

export const CampaignBuilderLoader = () => {
  return (
    <div className="card_main border mt-3 mb-3 p-3">
      <div className="campaign-builder-loader">
        <div>
          <div className="campaign-stage-box card__campaign loading"></div>
          <div className="jointer-sec">
            <div className="ms-0 me-0 row">
              <div className="ps-0 pe-0 text-end col-6">
                <div className="h-jointer text-center">
                  <span>5</span>
                </div>
                <div className="jointer"></div>
                <div className="campaign-stage-box card__campaign loading"></div>
              </div>
              <div className="ps-0 pe-0 text-start col-6">
                <div className="h-jointer h-jointer-r text-center">
                  <span>5</span>
                </div>
                <div className="jointer"></div>
                <div className="campaign-stage-box card__campaign loading"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MetricLoader = () => {
  return (
    <div
      className="card_main border mb-3 ps-3 pe-3"
      style={{ height: "calc(100% - 2rem)", minHeight: "153px" }}
    >
      <div className="campaign-builder-loader">
        <div
          className="d-flex justify-content-between "
          style={{ minHeight: "75px" }}
        >
          <div>
            <LineLoader height="15px" width="100px" className={" mb-1"} />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-4"}
            />
          </div>

          <div>
            <div className="d-flex justify-content-between ">
              <LineLoader
                width="30px"
                height="30px"
                className={"inline-block round-circle"}
              />
              <div className="ms-2">
                <LineLoader height="12px" width="50px" className={"p-1 mb-1"} />
                <LineLoader
                  height="10px"
                  width="30px"
                  className={"p-1 mt-0 mb-4"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between ">
          <div>
            <LineLoader height="12px" width="100px" className={" mb-1"} />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-3"}
            />
          </div>

          <div>
            <LineLoader
              height="12px"
              width="100px"
              className={" mb-1 ms-auto"}
            />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-3 ms-auto"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const MetricRatioLoader = () => {
  return (
    <div
      className="card_main border mb-3 ps-3 pe-3"
      style={{ height: "calc(100% - 2rem)", minHeight: "153px" }}
    >
      <div className="campaign-builder-loader">
        <div className="d-flex justify-content-between ">
          <div>
            <LineLoader height="15px" width="100px" className={" mb-1"} />
            <RatioLoader
              width="70px"
              height="70px"
              className={"inline-block round-circle text-center"}
            />
          </div>

          <div>
            <div className="d-flex flex-column mt-5 pt-2">
              <div className="d-flex align-items-center">
                <LineLoader
                  width="15px"
                  height="15px"
                  className={"inline-block round-circle me-2 mt-0"}
                />
                <LineLoader
                  height="12px"
                  width="60px"
                  className={"p-1 mb-0 d-inline-block mt-0"}
                />
              </div>
              <div className="d-flex align-items-center mt-1">
                <LineLoader
                  width="15px"
                  height="15px"
                  className={"inline-block round-circle me-2 mt-0"}
                />
                <LineLoader
                  height="12px"
                  width="60px"
                  className={"p-1 mb-0 d-inline-block mt-0"}
                />
              </div>
              <div className="d-flex align-items-center mt-1">
                <LineLoader
                  width="15px"
                  height="15px"
                  className={"inline-block round-circle me-2 mt-0"}
                />
                <LineLoader
                  height="12px"
                  width="60px"
                  className={"p-1 mb-0 d-inline-block mt-0"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MetricVersesLoader = () => {
  return (
    <div
      className="card_main border mb-3 ps-3 pe-3"
      style={{ height: "calc(100% - 2rem)", minHeight: "153px" }}
    >
      <div className="campaign-builder-loader">
        <div className="d-flex justify-content-between ">
          <div>
            <LineLoader height="15px" width="100px" className={" mb-5"} />
          </div>
        </div>
        <div className="d-flex justify-content-between ">
          <div>
            <LineLoader height="12px" width="100px" className={" mb-1"} />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-2"}
            />
          </div>

          <div>
            <LineLoader
              height="12px"
              width="100px"
              className={" mb-1 ms-auto"}
            />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-2 ms-auto"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const EqupSuggestions = () => {
  return (
    <div
      className="card_main border mt-3 mb-3 ps-3 pe-3"
      style={{ height: "calc(100% - 2rem)" }}
    >
      <div className="campaign-builder-loader position-relative">
        <div
          style={{
            position: "absolute",
            background: " #e2e2e2",
            width: "2px",
            top: "20px",
            bottom: "10px",
            left: "13px",
          }}
        ></div>
        <div className="d-flex align-items-center">
          <LineLoader width="30px" height="28px" className={"round-circle"} />
          <LineLoader height="10px" width="100%" className={"ms-3 mb-0"} />
        </div>
        <div className="d-flex align-items-center">
          <LineLoader width="30px" height="28px" className={"round-circle"} />
          <LineLoader height="10px" width="100%" className={"ms-3 mb-0"} />
        </div>
        <div className="d-flex align-items-center">
          <LineLoader width="30px" height="28px" className={"round-circle"} />
          <LineLoader height="10px" width="100%" className={"ms-3 mb-0"} />
        </div>
        <div className="d-flex align-items-center">
          <LineLoader width="30px" height="28px" className={"round-circle"} />
          <LineLoader height="10px" width="100%" className={"ms-3 mb-0"} />
        </div>
        <div className="d-flex align-items-center mb-3">
          <LineLoader width="30px" height="28px" className={"round-circle"} />
          <LineLoader height="10px" width="100%" className={"ms-3 mb-0"} />
        </div>
      </div>
    </div>
  );
};

export const DealsFunnel = () => {
  return (
    <div
      className="card_main border ps-3 pe-3 d-flex align-items-center"
      style={{ height: "calc(100% - 2rem" }}
    >
      <div className="campaign-builder-loader mt-3 funnel-loader-layers">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="funnel-right-text">
          <div
            style={{
              position: "absolute",
              background: " #e2e2e2",
              width: "1px",
              top: "0",
              bottom: "21px",
              left: "12px",
            }}
          ></div>
          <div className="d-flex ps-1 mb-3">
            <LineLoader
              width="15px"
              height="15px"
              className={"round-circle flex-shrink-1 mt-0"}
            />
            <div className="w-100 ms-3">
              <LineLoader height="10px" className={"mb-0 w-100 mt-0 p-0"} />
              <LineLoader height="8px" className={"mb-0 w-90 mt-1 p-0"} />
            </div>
          </div>
          <div className="d-flex ps-1 mb-3">
            <LineLoader
              width="15px"
              height="15px"
              className={"round-circle flex-shrink-1 mt-0"}
            />
            <div className="w-100 ms-3">
              <LineLoader height="10px" className={"mb-0 w-100 mt-0 p-0"} />
              <LineLoader height="8px" className={"mb-0 w-90 mt-1 p-0"} />
            </div>
          </div>
          <div className="d-flex ps-1 mb-3">
            <LineLoader
              width="15px"
              height="15px"
              className={"round-circle flex-shrink-1 mt-0"}
            />
            <div className="w-100 ms-3">
              <LineLoader height="10px" className={"mb-0 w-100 mt-0 p-0"} />
              <LineLoader height="8px" className={"mb-0 w-90 mt-1 p-0"} />
            </div>
          </div>
          <div className="d-flex ps-1 mb-3">
            <LineLoader
              width="15px"
              height="15px"
              className={"round-circle flex-shrink-1 mt-0"}
            />
            <div className="w-100 ms-3">
              <LineLoader height="10px" className={"mb-0 w-100 mt-0 p-0"} />
              <LineLoader height="8px" className={"mb-0 w-90 mt-1 p-0"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ScriptURLLoader = () => {
  return (
    <div className="card_main" style={{ height: "auto" }}>
      <div className="flex-justify">
        <LineLoader className={"w-10"} height="20px" marginTop="0" />
        <LineLoader className={"w-20"} height="20px" marginTop="0" />
        <LineLoader className={"w-10"} height="20px" marginTop="0" />
        <LineLoader className={"w-35"} height="20px" marginTop="0" />
        <LineLoader className={"w-15"} height="20px" marginTop="0" />
      </div>
    </div>
  );
};

export const DealBoardLoader = () => {
  return (
    <div className="card_main border mt-3 mb-3 h-auto">
      <div className="campaign-builder-loader ">
        <div className="d-flex">
          <StageLoader />
          <StageLoader />
          <StageLoader />
          <StageLoader />
        </div>
      </div>
    </div>
  );
};

export const StageLoader = () => {
  return (
    <div className="card_main mt-3 mb-3 ps-3 pe-3">
      <div
        className="campaign-builder-loader border ps-3 pe-3"
        style={{
          backgroundColor: "#eee",
        }}
      >
        <DealLoader />
        <DealLoader />
        <DealLoader />
      </div>
    </div>
  );
};

export const DealLoader = () => {
  return (
    <div
      className="card_main border mt-3 mb-3 ps-3 pe-3"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div className="campaign-builder-loader">
        <div className="d-flex justify-content-between ">
          <div>
            <LineLoader height="15px" width="100px" className={" mb-5"} />
          </div>
        </div>
        <div className="d-flex justify-content-between ">
          <div>
            <LineLoader height="12px" width="100px" className={" mb-1"} />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-2"}
            />
          </div>

          <div>
            <LineLoader
              height="12px"
              maxWidth="100px"
              className={" mb-1 ms-auto"}
            />
            <LineLoader
              height="10px"
              width="60px"
              className={"p-1 mt-0 mb-2 ms-auto"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormLoader = () => {
  return (
    <div
      className="card_main"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div className="campaign-builder-loader">
        <div>
          <LineLoader height="20px" width="120px" className={" mb-3"} />
        </div>
        <div>
          <LineLoader height="40px" width="100%" className={" mb-2"} />
        </div>

        <div className="d-flex justify-content-end mb-3">
          <LineLoader height="35px" width="100px" className="ms-2" />
          <LineLoader height="35px" width="100px" className="ms-2" />
        </div>
      </div>
    </div>
  );
};
// only graph loader...
export const EmailStatsLoader = () => {
  return (
    <div className="card_main border mb-3 border-0">
      <div className="campaign-builder-loader">
        <div className="text-center">
          <div>
            <RatioLoader
              width="150px"
              height="150px"
              className={"inline-block round-circle text-center p-4"}
            />
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ gap: "1rem" }}
          >
            <LineLoader
              height="15px"
              width="70px"
              className={"inline-block mb-1 p-0"}
            />
            <LineLoader
              height="15px"
              width="70px"
              className={"inline-block mb-1 p-0"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Social link buttons loader...
export const EmailLinkedAccountLoader = () => {
  return (
    <div className="card_main border mb-3 border-0">
      <div className="campaign-builder-loader">
        <div className="text-center">
          <div style={{ gap: "1rem", maxWidth: "350px", margin: "auto" }}>
            <LineLoader height="40px" width="100%" className={"mb-1 p-0"} />
            <LineLoader height="40px" width="100%" className={"mb-1 p-0"} />
            <LineLoader height="40px" width="100%" className={"mb-1 p-0"} />
            <LineLoader height="40px" width="100%" className={"mb-1 p-0"} />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * send email loader...
 * @returns
 */
export const SendEmailLoader = () => {
  return (
    <div className="card_main border mb-3 border-0">
      <div className="campaign-builder-loader">
        <div className="text-center">
          <div>
            <LineLoader height="20px" width="100%" className={"mb-1 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-1 p-0"} />
            <LineLoader height="250px" width="100%" className={"mb-1 p-0"} />
            <div className="d-flex justify-content-end" style={{ gap: "1rem" }}>
              <LineLoader height="36px" width="80px" className={"mb-1 p-0"} />
              <LineLoader height="36px" width="80px" className={"mb-1 p-0"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Dashboard recommended tasks loader
 */
export const DashboardLoader = () => {
  return (
    <div style={{ maxWidth: "60rem", margin: "auto" }} className="mt-3 p-2">
      <div className="card_main border mb-3 border-0 p-2">
        <div className="campaign-builder-loader">
          <div className="text-center">
            <div>
              <LineLoader height="25px" width="100%" className={"mb-1 p-0"} />
              <LineLoader
                height="18px"
                width="60%"
                className={"mb-1 p-0 inline-block vertical-align-top"}
              />
            </div>
          </div>
          <div className="p-4 mt-4" style={{ boxShadow: "0 0 10px #eee" }}>
            <div className="p-2 border-bottom">
              <LineLoader height="20px" width="200px" className={"p-0 mt-0"} />
              <LineLoader
                height="10px"
                width="100%"
                className={"p-0 inline-block vertical-align-top"}
              />
              <LineLoader
                height="15px"
                width="60%"
                className={"mt-2 mb-1 p-0 inline-block vertical-align-top"}
              />
            </div>

            <LineDashboardLoaderComponent />
            <LineDashboardLoaderComponent />
            <LineDashboardLoaderComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export const CallLogLoader = () => {
  return (
    <div style={{ maxWidth: "60rem", margin: "auto" }} className="mt-3 p-2">
      <div className="card_main border mb-3 border-0 p-2">
        <div className="campaign-builder-loader">
          <div className="mt-3 mb-4 py-2">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <LineLoader
                width="50px"
                height="50px"
                className={"round-circle flex-shrink-0"}
              />
              <div className="p1" style={{ flex: "1 1 0" }}>
                <LineLoader height="20px" width="200px" className={"p-0"} />
                <LineLoader height="15px" width="200px" className={"p-0"} />
              </div>
            </div>
          </div>
          <div className="mt-3 mb-4 py-2">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <LineLoader
                width="50px"
                height="50px"
                className={"round-circle flex-shrink-0"}
              />
              <div className="p1" style={{ flex: "1 1 0" }}>
                <LineLoader height="20px" width="200px" className={"p-0"} />
                <LineLoader height="15px" width="200px" className={"p-0"} />
              </div>
            </div>
          </div>
          <div className="mt-3 mb-4 py-2">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <LineLoader
                width="50px"
                height="50px"
                className={"round-circle flex-shrink-0"}
              />
              <div className="p1" style={{ flex: "1 1 0" }}>
                <LineLoader height="20px" width="200px" className={"p-0"} />
                <LineLoader height="15px" width="200px" className={"p-0"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SMSLoader = () => {
  return (
    <div style={{ maxWidth: "60rem", margin: "auto" }} className="mt-3 p-2">
      <div className="card_main border mb-3 border-0 p-2">
        <div className="campaign-builder-loader">
          <div className="mt-3 mb-4 py-2">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <LineLoader
                width="50px"
                height="50px"
                className={"round-circle flex-shrink-0"}
              />
              <div className="p1" style={{ flex: "1 1 0" }}>
                <LineLoader height="20px" width="200px" className={"p-0"} />
                <LineLoader height="15px" width="200px" className={"p-0"} />
              </div>
            </div>
          </div>
          <div className="mt-3 mb-4 py-2">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <LineLoader
                width="50px"
                height="50px"
                className={"round-circle flex-shrink-0"}
              />
              <div className="p1" style={{ flex: "1 1 0" }}>
                <LineLoader height="20px" width="200px" className={"p-0"} />
                <LineLoader height="15px" width="200px" className={"p-0"} />
              </div>
            </div>
          </div>
          <div className="mt-3 mb-4 py-2">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <LineLoader
                width="50px"
                height="50px"
                className={"round-circle flex-shrink-0"}
              />
              <div className="p1" style={{ flex: "1 1 0" }}>
                <LineLoader height="20px" width="200px" className={"p-0"} />
                <LineLoader height="15px" width="200px" className={"p-0"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EngagementLoader = () => {
  return (
    <>
      <ListGroup.Item key={1} className="conversation-list-item">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="conversation-icon">
              <LineLoader
                width="30px"
                height="30px"
                className={"round-circle flex-shrink-0"}
              />
            </div>
            <div>
              <h5 className="mb-0">
                <LineLoader height="20px" width="200px" className={"p-0"} />
              </h5>
            </div>
          </div>
        </div>
        <div>
          <div className="text-muted mb-0 description">
            <LineLoader height="20px" width="200px" className={"p-0"} />
            <LineLoader height="15px" width="200px" className={"p-0"} />
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item key={2} className="conversation-list-item">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="conversation-icon">
              <LineLoader
                width="30px"
                height="30px"
                className={"round-circle flex-shrink-0"}
              />
            </div>
            <div>
              <h5 className="mb-0">
                <LineLoader height="20px" width="200px" className={"p-0"} />
              </h5>
            </div>
          </div>
        </div>
        <div>
          <div className="text-muted mb-0 description">
            <LineLoader height="20px" width="200px" className={"p-0"} />
            <LineLoader height="15px" width="200px" className={"p-0"} />
          </div>
        </div>
      </ListGroup.Item>
    </>
  );
};

export const SingleLineContactLoader = () => {
  return (
    <>
      <ListGroup.Item key={1} className="conversation-list-item">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="conversation-icon">
              <LineLoader
                width="30px"
                height="30px"
                className={"round-circle flex-shrink-0"}
              />
            </div>
            <div>
              <h5 className="mb-0">
                <LineLoader height="20px" width="200px" className={"p-0"} />
              </h5>
            </div>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item key={2} className="conversation-list-item">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="conversation-icon">
              <LineLoader
                width="30px"
                height="30px"
                className={"round-circle flex-shrink-0"}
              />
            </div>
            <div>
              <h5 className="mb-0">
                <LineLoader height="20px" width="200px" className={"p-0"} />
              </h5>
            </div>
          </div>
        </div>
      </ListGroup.Item>
    </>
  );
};

export const ChatLoader = () => {
  return (
    <div className="message-list">
      <div className="message" key={1}>
        {/* Render individual message */}
        <div className="chat-room">
          <div className={"message message-right"} key={1}>
            <div className="message-inner">
              <div className="avtar avtar-small">
                <LineLoader
                  width="35px"
                  height="35px"
                  marginTop="unset"
                  className={"round-circle flex-shrink-0"}
                />
              </div>
              <div className="message-text">
                <p>
                  <LineLoader height="20px" width="200px" className={"p-0"} />
                </p>
                <span className="day-time">
                  {" "}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="message" key={2}>
        {/* Render individual message */}
        <div className="chat-room">
          <div className={"message message-left"} key={2}>
            <div className="message-inner">
              <div className="avtar avtar-small">
                <LineLoader
                  width="35px"
                  height="35px"
                  marginTop="unset"
                  className={"round-circle flex-shrink-0"}
                />
              </div>
              <div className="message-text">
                <p>
                  <LineLoader height="20px" width="200px" className={"p-0"} />
                </p>
                <span className="day-time">
                  {" "}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="message" key={2}>
        {/* Render individual message */}
        <div className="chat-room">
          <div className={"message message-right"} key={2}>
            <div className="message-inner">
              <div className="avtar avtar-small">
                <LineLoader
                  width="35px"
                  height="35px"
                  marginTop="unset"
                  className={"round-circle flex-shrink-0"}
                />
              </div>
              <div className="message-text">
                <p>
                  <LineLoader height="20px" width="200px" className={"p-0"} />
                </p>
                <span className="day-time">
                  {" "}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * recommended tasks line on dashboard loader...
 * @returns
 */
export const LineDashboardLoaderComponent = () => {
  return (
    <div className="mt-3 mb-4 py-2">
      <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
        <LineLoader
          width="50px"
          height="50px"
          className={"round-circle flex-shrink-0"}
        />
        <div className="p1" style={{ flex: "1 1 0" }}>
          <LineLoader height="20px" width="200px" className={"p-0"} />
          <LineLoader height="15px" width="200px" className={"p-0"} />
        </div>
        <LineLoader width="70px" height="35px" className={"flex-shrink-0"} />
      </div>
    </div>
  );
};
export const BarChartLoader = () => {
  return (
    <div
      className="card_main border mb-3 d-flex align-items-center justify-content-center p-4"
      style={{ height: "calc(100% - 2rem" }}
    >
      <div className="campaign-builder-loader mb-3 mt-3">
        <div className="text-center">
          <div style={{}}>
            <div
              className="d-flex align-items-end justify-content-around"
              style={{ gap: "1rem" }}
            >
              <LineLoader
                height="210px"
                width="25px"
                className={"mb-0 p-0 rounded-0"}
              />
              <LineLoader
                height="160px"
                width="25px"
                className={"mb-0 p-0 rounded-0"}
              />
              <LineLoader
                height="140px"
                width="25px"
                className={"mb-0 p-0 rounded-0"}
              />
              <LineLoader
                height="230px"
                width="25px"
                className={"mb-0 p-0 rounded-0"}
              />
              <LineLoader
                height="200px"
                width="25px"
                className={"mb-0 p-0 rounded-0"}
              />
              <LineLoader
                height="100px"
                width="25px"
                className={"mb-0 p-0 rounded-0"}
              />
            </div>
            <LineLoader height="5px" width="100%" className={"mt-0 mb-1 p-0"} />
          </div>
          <div
            className="d-flex align-items-end justify-content-around"
            style={{ gap: "1rem" }}
          >
            <LineLoader height="10px" width="45px" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="45px" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="45px" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="45px" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="45px" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="45px" className={"mb-0 p-0"} />
          </div>
        </div>
      </div>
    </div>
  );
};
export const DealsChartLoader = () => {
  return (
    <div
      className="card_main border mb-3 d-flex align-items-center p-4"
      style={{ height: "calc(100% - 2rem" }}
    >
      <div className="campaign-builder-loader" style={{ flex: "1 1 auto" }}>
        <div className="d-flex align-items-center">
          <div className="me-3" style={{ minWidth: "80px" }}>
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
            <LineLoader
              height="10px"
              width="100%"
              className={"mb-0 p-0 rounded-0"}
            />
          </div>
          <LineLoader height="260px" width="3px" className={"mb-1 p-0"} />
          <div style={{ flex: "1 1 0" }}>
            <LineLoader
              height="10px"
              width="100%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="100%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="90%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="80%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="50%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="50%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="50%"
              className={"mb-0 p-0 rounded-0"}
            />
            <LineLoader
              height="10px"
              width="50%"
              className={"mb-0 p-0 rounded-0"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PaymentGatewayLoader = () => {
  return (
    <div
      className="card_main border p-3"
      style={{
        backgroundColor: "#fff",
        overflow: "visible",
      }}
    >
      <div className="payment-gateway-loader">
        <LineLoader
          width="100px"
          height="35px"
          className={"payment-logo mt-0"}
        />
      </div>
      <div>
        <div>
          <LineLoader height="15px" className={"mb-1 mt-4"} />
          <LineLoader
            height="15px"
            width="80%"
            className={" mb-4 ms-auto me-auto"}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <LineLoader height="12px" width="100px" className={" mb-1"} />
          </div>

          <div>
            <LineLoader height="25px" width="100px" className={" mb-1"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const MessageLoader = () => {
  return (
    <div className="card_main p-3 mt-3 mb-3 text-center">
      <div className="card__image loading m-auto"></div>
      <LineLoader className={"loading p-3"} />
      <div className="d-inline-block">
        <LineLoader
          width="100px"
          height="30px"
          className={"loading  d-inline-block me-2"}
        />
        <LineLoader
          width="100px"
          height="30px"
          className={"loading d-inline-block ms-2"}
        />
      </div>
    </div>
  );
};

export const GalleryLoading = () => {
  return (
    <div className="card_main p-0 mt-0 mb-0" style={{ maxWidth: "100%" }}>
      <div className="d-flex">
        <div className="p-1" style={{ flex: "0 0 20%" }}>
          <LineLoader className={"loading p-2 mt-0"} height="60px" />
        </div>
        <div className="p-1" style={{ flex: "0 0 20%" }}>
          <LineLoader className={"loading p-2 mt-0"} height="60px" />
        </div>
        <div className="p-1" style={{ flex: "0 0 20%" }}>
          <LineLoader className={"loading p-2 mt-0"} height="60px" />
        </div>
        <div className="p-1" style={{ flex: "0 0 20%" }}>
          <LineLoader className={"loading p-2 mt-0"} height="60px" />
        </div>
        <div className="p-1" style={{ flex: "0 0 20%" }}>
          <LineLoader className={"loading p-2 mt-0"} height="60px" />
        </div>
      </div>
    </div>
  );
};

// Global Search
export const SearchResultLoading = () => {
  return (
    <div className="card_main p-0 mt-0 mb-0">
      <SearchResultLoadingItem />
      <SearchResultLoadingItem />
      <SearchResultLoadingItem />
      <SearchResultLoadingItem />
      <SearchResultLoadingItem />
    </div>
  );
};
export const SearchResultLoadingItem = () => {
  return (
    <div className="d-flex align-items-center mb-4" style={{ gap: "1rem" }}>
      <LineLoader
        width="30px"
        height="30px"
        className={"round-circle flex-shrink-0 mt-0"}
      />
      <div className="p1" style={{ flex: "1 1 0" }}>
        <LineLoader height="13px" width="80%" className={"p-0 mt-0 mb-2"} />
        <LineLoader height="10px" className={"p-0 mt-0"} />
      </div>
    </div>
  );
};
// Global Search End

export const CampaignFormLoader = () => {
  return (
    <div className="card_main p-0 mt-0 mb-0">
      <div className="p1 mb-3">
        <LineLoader height="12px" width="100px" className={"p-0 mt-0 mb-2"} />
        <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-2"} />
      </div>
      <div className="p1 mb-3">
        <LineLoader height="12px" width="100px" className={"p-0 mt-0 mb-2"} />
        <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-2"} />
      </div>
    </div>
  );
};

/*** EDIT COLUMN LOADER ***/
export const EditColumnLoader = () => {
  return (
    <div className="card_main p-0 mt-0 mb-0">
      <Row>
        <Col md xs="12">
          <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-2"} />
          <hr />
          <LineLoader height="12px" width="100px" className={"p-0 mt-0 mb-4"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="12px" width="50%" className={"p-0 mt-0 mb-3"} />
        </Col>
        <Col md xs="12">
          <LineLoader height="12px" width="100px" className={"p-0 mt-0 mb-4"} />
          <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-3"} />
          <LineLoader height="30px" width="100%" className={"p-0 mt-0 mb-3"} />
        </Col>
      </Row>
    </div>
  );
};

export const PlansLoader = () => {
  return (
    <Row
      className="card_main border mb-3 align-items-center p-4"
      style={{ height: "calc(100% - 2rem" }}
    >
      <Col md="3" lg="3">
        <div style={{ minWidth: "100%" }}>
          <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
        </div>
      </Col>
      <Col md="3" lg="3">
        <div style={{ minWidth: "100%" }}>
          <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
        </div>
      </Col>
      <Col md="3" lg="3">
        <div style={{ minWidth: "100%" }}>
          <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
        </div>
      </Col>
      <Col md="3" lg="3">
        <div style={{ minWidth: "100%" }}>
          <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
          <LineLoader height="10px" width="100%" className={"mb-0 p-0"} />
        </div>
      </Col>
    </Row>
  );
};

export const SmtpImapLoader = () => {
  return (
    <React.Fragment>
      <Row className="mt-4 mb-1 align-items-center p-2">
        <Col md="6" lg="6">
          <div style={{ minWidth: "90%" }}>
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
          </div>
        </Col>
        <Col md="6" lg="6">
          <div style={{ minWidth: "90%" }}>
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
          </div>
        </Col>
      </Row>
      <Row className=" align-items-center p-2">
        <Col md="6" lg="6">
          <div style={{ minWidth: "90%" }}>
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
          </div>
        </Col>
        <Col md="6" lg="6">
          <div style={{ minWidth: "90%" }}>
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="20px" width="100%" className={"mb-0 p-0"} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export const PaymentMethodLoader = () => {
  return (
    <div
      className="card_main"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Row>
        <Col md="6" lg="6">
          <div style={{ minWidth: "90%" }}>
            <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
            <LineLoader height="50px" width="100%" className={"mb-0 p-0"} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
