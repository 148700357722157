import React, { useContext, useReducer } from 'react';
import { currentTeamId } from '../Utils/utils';

import { useLazyQuery } from '@apollo/client';
import { UNVERIFIED_CONTACTS_LIST } from '../GraphApi/VerifyContactEmail';
import { TrialPlanChecker } from './TrialPlanChecker';

const initialState = {
  isFetching: false,
  checked: null,
  processId: null,
  error: null,
};

export const UnverifiedContactsContext = React.createContext();

function UnverifiedContactReducer(state, action) {
  switch (action.type) {
    case 'GET_UNVERIFIED_REQUEST':
      return { ...state, isFetching: true };
    case 'REMOVE_UNVERIFIED_REQUEST':
      return { ...state, isFetching: true };
    case 'REMOVE_UNVERIFIED_SUCCESS':
      return { ...state, isContactRemoved: true };
    case 'UNVERIFIED_SUCCESS':
      return {
        ...state,
        isFetching: false,
        checked:
          action.payload && action.payload.checked > -1
            ? action.payload.checked
            : null,
        processId:
          action.payload && action.payload.id ? action.payload.id : null,
        error: null,
      };
    case 'UNVERIFIED_FAILURE':
      return {
        ...state,
        checked: null,
        processId: null,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

const UnverifiedContactsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    UnverifiedContactReducer,
    initialState,
    undefined
  );
  const { isPlanExpired } = useContext(TrialPlanChecker);

  const [getTeamNotification] = useLazyQuery(UNVERIFIED_CONTACTS_LIST, {
    fetchPolicy: 'no-cache',
  });

  // reload Unverified entries after set interval...
  const loadUnverifiedEntries = () => {
    // if (planData !== null) {
    if (isPlanExpired) {
      return false;
    } else {
      getTeamNotification({
        variables: {
          team_id: currentTeamId,
        },
        fetchPolicy: 'no-cache',
      })
        .then((result) => {
          if (result.data) {
            const contactsData = result.data.getTeamNotification;
            if (contactsData !== null) {
              dispatch({
                type: 'UNVERIFIED_SUCCESS',
                payload: contactsData,
              });
            } else {
              dispatch({
                type: 'UNVERIFIED_SUCCESS',
                payload: { checked: null, processId: null },
              });
            }
          }
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          dispatch({ type: 'UNVERIFIED_FAILURE', payload: graphQLErrors });
        });
    }
    // }
  };

  /*******/
  const value = {
    unverifiedData: {
      isFetching: state.isFetching,
      checked: state.checked,
      processId: state.processId,
      error: state.error,
    },

    getUnverifiedData: () => {
      dispatch({ type: 'GET_UNVERIFIED_REQUEST' });
      loadUnverifiedEntries();
    },

    setUnverifiedData: (data) => {
      dispatch({ type: 'UNVERIFIED_SUCCESS', payload: data });
    },
  };

  return (
    <UnverifiedContactsContext.Provider value={value}>
      {children}
    </UnverifiedContactsContext.Provider>
  );
};

export default UnverifiedContactsContextProvider;
