import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import "./navigation.scss";
import { AuthContext } from "../Context/AuthContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  currentTeamId,
  getUserIndustry,
  getUserTeams,
  getValidationMessage,
  hasClient,
} from "../Utils/utils";
import { SearchListing } from "../Components/Common/Search/SearchListing";
import { Navbar, Nav, NavDropdown, Button, FormControl } from "react-bootstrap";
import { globalSearch } from "../RestApi/RestApi";
import { Notification } from "./Notification";
import { useTranslation } from "react-i18next";
import { GENERATE_PIN } from "../GraphApi/TeamMembers";
import { TopNotification } from "../Components/Common/Notification/TopNotification";
import DuplicateContactsNotification from "../Components/Common/Notification/DuplicateContactsNotification";
import { ANNOUNCEMENTS } from "../GraphApi/Announcement";
import { AnnouncementPreview } from "../Components/Pages/Settings/Announcements/AnnouncementPreview";
import Session from "../Utils/Session";
import UnverifiedContactsNotification from "../Components/Common/Notification/TeamNotification/UnverifiedContactsNotification";
import { getSettings } from "../Utils/CurrentSetting";
import { ModulePermission } from "../Context/ModulePermission";
import copy from "copy-to-clipboard";
import { SwitchCompanyPopup } from "../Components/Common/Modals/SwitchCompanyPopup";
import { equpIndustryInfo } from "../Utils/commonParams";
import PaymentFailedNotification from "../Components/Common/Notification/TeamNotification/PaymentFailedNotification";

const urlSchema = {
  crm: ["crm", "contact-details", "company-management", "company-details"],
  dashboard: [
    "dashboard/admin",
    "dashboard/technician",
    "dashboard/sales",
    "dashboard/marketing",
  ],
  contact: ["crm", "contact-details"],
  owners: ["owners"],
  company: ["company-management", "company-details"],
  sales: ["sales/pipeline", "service-request", "sales/service-request"],
  jobs: [
    "jobs",
    "jobs/create-job",
    "estimates",
    "jobs/service-visit",
    "jobs/dispatcher",
  ],
  marketing: [
    "/marketing/automation",
    "/marketing/campaigns",
    "/marketing/campaign-builder",
    "/marketing/campaign-analytics",
    "/marketing/choose-prebuilt-campaigns",
    "/marketing/landing-pages",
    "/marketing/forms",
    "/social-media",
  ],
  workflow: ["workflow", "workflow-builder", "choose-prebuilt-workflows"],
  engagements: ["engagements"],
  catalog: [
    "services",
    "subscriptions",
    "ecommerce",
    "subscription",
    "add-plan",
    "add-layout",
  ],
  profile: ["my-profile"],
  settings: ["settings"],
  social: [
    "social-media",
    "social-media/add-account",
    "social-media/social-post",
    "social-media/monitoring",
  ],
};

const Navigation = ({ companyLogo, userImage }) => {
  const { pathname } = useLocation();
  const { t: locale } = useTranslation(["common", "pages"]);
  const [isActive, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!isActive);
  };

  const [state, setState] = useState({
    typingTimeout: 0,
  });

  const [industryInfo, setIndustryInfo] = useState(equpIndustryInfo);
  const [industry, setIndustry] = useState("");
  const { logOut, userData } = useContext(AuthContext);
  const { setOnPage, isShowAll, allowedPages } = useContext(ModulePermission);
  const [handleSearch, setHandleSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentCompanySetting, setCurrentCompanySetting] = useState(null);
  const [savedPin, setSavedPin] = useState(null);
  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [pathName, setPathName] = useState("");

  /*****/
  const [getNewPin] = useMutation(GENERATE_PIN);
  const [allAnnouncements] = useLazyQuery(ANNOUNCEMENTS);

  const fetchAnnouncements = () => {
    allAnnouncements({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    }).then((result) => {
      if (result.data && !result.error) {
        setAnnouncements(result.data.allAnnouncements);
        // dataArrangeByDate(result.data.allAnnouncements);
        if (result.data.allAnnouncements.length > 0) handleShow();
      }
    });
  };

  const handleClose = () => {
    setShowAnnouncements(false);
    Session.storeSessionVar("fsp_announcements", true);
  };

  const handleShow = () => setShowAnnouncements(true);

  useEffect(() => {
    if (pathname) {
      setPathName(pathname.replace("/", ""));
    }
  }, [pathname]);

  useEffect(() => {
    if (currentTeamId && !Session.getSessionVar("fsp_announcements")) {
      fetchAnnouncements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  useEffect(() => {
    if (userData && userData.authData) {
      const userIndustry = getUserIndustry(userData);
      if (userIndustry) {
        setIndustry(userIndustry);
        setIndustryInfo({
          ...equpIndustryInfo,
          [userIndustry]: true,
        });

        document.title = userData.authData.company
          ? userData.authData.company
          : "FieldServicePro";
      }

      setSavedPin(
        userData.authData.pin_data ? userData.authData.pin_data : null
      );
      if (
        userData.authData.currentTeam &&
        userData.authData.currentTeam.settings
      ) {
        const companySettings = getSettings(
          userData.authData.currentTeam.settings
        );
        setCurrentCompanySetting(companySettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const updateBodyClass = () => {
    if (document.body.classList.contains("modal-open")) {
      document.body.classList.remove("modal-open");
    } else {
      document.body.classList.add("modal-open");
    }
  };

  const [searchData, setSearchData] = useState(null);

  const logOutUser = async () => {
    await logOut();
  };

  const [expanded, setExpanded] = useState(false);

  /* Handle Search */
  const getSearchValue = (searchedText) => {
    if (searchedText.length >= 3) {
      clearTimeout(state.typingTimeout);
      setState({
        typingTimeout: setTimeout(function () {
          setLoading(true);
          globalSearch({
            team_id: currentTeamId,
            q: searchedText,
          })
            .then((data) => {
              // console.log(data);
              setSearchData(data.data);
              setLoading(false);
            })
            .catch((error) => {
              console.log("search Error:", error);
              setLoading(false);
            });
          setHandleSearch(true);
        }, 600),
      });
    } else if (searchedText.length === 0 || !searchedText) {
      clearTimeout(state.typingTimeout);
      setLoading(false);
      setSearchData(null);
    }
  };

  const navigationDrawerHide = () => {
    if (document.body.clientWidth <= 991) {
      setExpanded(expanded ? false : "expanded");
      updateBodyClass();
    }
  };

  const refreshPin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getNewPin({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        result.data.updatePin.status && setSavedPin(result.data.updatePin.pin);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log("error", getValidationMessage(graphQLErrors));
      });
  };

  /**
   * Show Navigation Menu Items
   **/
  const showMenuItems = (name) => {
    return true;
    // if (allowedPages.length > 0 && allowedPages.indexOf(name) > -1) {
    //   return true;
    // } else {
    //   return isShowAll;
    // }
  };

  const onCopyMessage = (value) => {
    copy(value, {
      debug: true,
      message: locale("Press #{key} to copy"),
    });
  };
  const [shortcutDropdown] = useState(<i className="ri-star-line"></i>);

  /*******/
  return (
    <React.Fragment>
      <PaymentFailedNotification />
      {pathName !== "engagements" && (
        <>
          <TopNotification />
          {!hasClient(userData) && (
            <DuplicateContactsNotification url={pathName} />
          )}
          <UnverifiedContactsNotification
            url={pathName}
            locale={locale}
            teamId={currentTeamId}
          />
        </>
      )}

      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        expanded={expanded}
        className={hasClient(userData) ? "justify-content-between" : ""}
      >
        <div>
          <Link className="navbar-brand" to="/dashboard">
            {companyLogo}
          </Link>
          <Navbar.Toggle onClick={() => navigationDrawerHide()} />
        </div>
        <Nav className="admin-action-navbar ms-auto">
          {!hasClient(userData) && (
            <React.Fragment>
              <div className="mobile-search-toggle d-xl-none">
                <Button variant="" onClick={handleToggle}>
                  <i className="ri-search-line"></i>
                </Button>
              </div>
              <div
                className={isActive ? "search-bar active" : " search-bar"}
                onBlur={() => setHandleSearch(false)}
              >
                <FormControl
                  type="text"
                  placeholder={locale("pages:navigation.Search")}
                  className="show-search-bar"
                  // onFocus={() => setHandleSearch(false)}
                  onChange={(e) => {
                    getSearchValue(e ? e.target.value : "");
                  }}
                  onBlur={(e) => {
                    e.target.value = "";
                  }}
                  id={"globalSearchBar"}
                  autoComplete="off"
                />
                <Button variant="">
                  <i className="ri-search-line"></i>
                </Button>

                <SearchListing
                  handleSearch={handleSearch}
                  setHandleSearch={setHandleSearch}
                  searchData={searchData}
                  industry={industry}
                  loading={loading}
                  allowedPages={allowedPages}
                  setOnPage={setOnPage}
                />
              </div>
            </React.Fragment>
          )}{" "}
          {!hasClient(userData) && false && (
            <NavDropdown
              align="end"
              className="shortcut-dropdown"
              title={shortcutDropdown}
            >
              <div className="shortcut-title">
                <h4>Shortcuts</h4>
                <a href="javascript:void(0)" className="dropdown-shortcuts-add">
                  <i className="ri-add-circle-line"></i>
                </a>
              </div>
              <div className="shortcut-list-outer">
                <Row>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-calendar-2-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">Calendar</h6>
                      <small className="text-muted mb-0">Appointments</small>
                    </Link>
                  </Col>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-dashboard-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">Dashboard</h6>
                      <small className="text-muted mb-0">User Profile</small>
                    </Link>
                  </Col>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-user-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">User App</h6>
                      <small className="text-muted mb-0">Manage Users</small>
                    </Link>
                  </Col>
                  <Col className="dropdown-shortcuts-item">
                    <Link href="">
                      <span className="dropdown-shortcuts-icon">
                        <i className="ri-article-line"></i>
                      </span>
                      <h6 className="dropdown-shortcuts-title">Invoice App</h6>
                      <small className="text-muted mb-0">Manage Accounts</small>
                    </Link>
                  </Col>
                </Row>
              </div>
            </NavDropdown>
          )}
          <React.Fragment>
            <Notification
              currentCompanySetting={currentCompanySetting}
              userData={userData}
              locale={locale}
              industry={industry}
            />
            <NavDropdown
              title={userImage}
              align="end"
              id="basic-nav-dropdown"
              className="admin-action-dropdown"
            >
              <NavDropdown.Item className={"dropdown-item"}>
                <i className="ri-key-fill"></i> {locale("pages:navigation.Pin")}
                <span className="ms-2">{savedPin}</span>
                <i className="ri-refresh-line ms-2" onClick={refreshPin}></i>
                <i
                  className="ri-file-copy-line text-primary cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCopyMessage(savedPin);
                  }}
                ></i>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/my-profile"
                state={{ industry: industry }}
                className={`dropdown-item ${
                  urlSchema["profile"].indexOf(pathName) > -1 ? "active" : ""
                }`}
              >
                <i className="ri-user-6-line"></i>{" "}
                {locale("pages:navigation.Profile")}
              </NavDropdown.Item>
              <React.Fragment>
                {getUserTeams(userData).length > 1 && <SwitchCompanyPopup />}
                {!hasClient(userData) && (
                  <NavDropdown.Item
                    as={Link}
                    to="/settings"
                    state={{ industry: industry }}
                    className={`dropdown-item ${
                      urlSchema["settings"].indexOf(pathName) > -1
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="ri-settings-5-line"></i>{" "}
                    {locale("pages:navigation.Settings")}
                  </NavDropdown.Item>
                )}
              </React.Fragment>
              <NavDropdown.Divider />{" "}
              <NavDropdown.Item onClick={logOutUser}>
                <i className="ri-logout-circle-line"></i>{" "}
                {locale("pages:navigation.Logout")}
              </NavDropdown.Item>
            </NavDropdown>
          </React.Fragment>
        </Nav>
        <Navbar.Collapse id="basic-navbar-nav" className={"primary-navbar"}>
          <Nav className="">
            <Link
              to="/dashboard"
              state={{ industry: industry }}
              className={`nav-link ${
                urlSchema["dashboard"].indexOf(pathName) > -1 ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.Dashboard")}
            </Link>
            {!hasClient(userData) && (
              <React.Fragment>
                {showMenuItems("crm") && (
                  <NavDropdown
                    title={locale("pages:navigation.CRM")}
                    renderMenuOnMount={true}
                    className={
                      urlSchema["crm"].indexOf(pathName) > -1 ? "active" : ""
                    }
                  >
                    <Link
                      to="/crm"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        urlSchema["contact"].indexOf(pathName) > -1
                          ? "active"
                          : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Contacts")}
                    </Link>
                    {showMenuItems("companies") && (
                      <Link
                        to="/company-management"
                        state={{ industry: industry }}
                        className={`dropdown-item ${
                          urlSchema["company"].indexOf(pathName) > -1
                            ? "active"
                            : ""
                        }`}
                        role="button"
                        onClick={() => navigationDrawerHide()}
                      >
                        {locale("pages:navigation.Companies")}
                      </Link>
                    )}
                  </NavDropdown>
                )}

                {showMenuItems("pipelines") && (
                  <NavDropdown
                    title={locale("pages:navigation.Sales")}
                    renderMenuOnMount={true}
                    className={
                      urlSchema["sales"].indexOf(pathName) > -1 ? "active" : ""
                    }
                  >
                    <Link
                      to="/sales/pipeline"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        urlSchema["sales"].indexOf(pathName) > -1
                          ? "active"
                          : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Sales Pipeline")}
                    </Link>

                    <Link
                      to="/sales/service-request"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "/sales/service-request" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Service Request")}
                    </Link>
                  </NavDropdown>
                )}

                {showMenuItems("engagements") && (
                  <Link
                    to="/engagements"
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema["engagements"].indexOf(pathName) > -1
                        ? "active"
                        : ""
                    }`}
                    role="button"
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale("pages:navigation.engagements")}
                  </Link>
                )}

                {showMenuItems("jobs") && (
                  <NavDropdown
                    title={locale("pages:navigation.Jobs")}
                    renderMenuOnMount={true}
                    className={
                      urlSchema["jobs"].indexOf(pathName) > -1 ? "active" : ""
                    }
                  >
                    <Link
                      to="/jobs"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "jobs" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Jobs")}
                    </Link>

                    <Link
                      to="/estimates"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "estimates" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Estimates")}
                    </Link>

                    {/* <Link
                      to="/jobs/service-visit"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "jobs/service-visit" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Service Visit")}
                    </Link> */}
                  </NavDropdown>
                )}
                <Link
                  to="/jobs/dispatcher"
                  state={{ industry: industry }}
                  className={`nav-link ${
                    "jobs/dispatcher" === pathName ? "active" : ""
                  }`}
                  role="button"
                  onClick={() => navigationDrawerHide()}
                >
                  {locale("pages:navigation.Dispatch")}
                </Link>
                <Link
                  to="/services"
                  state={{ industry: industry }}
                  role="button"
                  className={`nav-link ${
                    urlSchema["catalog"].indexOf(pathName) > -1 ? "active" : ""
                  }`}
                  onClick={() => navigationDrawerHide()}
                >
                  {locale("pages:navigation.Catalog")}
                </Link>

                {showMenuItems("workflows") && (
                  <Link
                    to="/workflow"
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema["workflow"].indexOf(pathName) > -1
                        ? "active"
                        : ""
                    }`}
                    role="button"
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale("pages:navigation.Workflows")}
                  </Link>
                )}

                {showMenuItems("campaigns") && (
                  <NavDropdown
                    title={locale("pages:navigation.Marketing")}
                    renderMenuOnMount={true}
                    className={
                      urlSchema["marketing"].indexOf(pathName) > -1
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to="/marketing/automation"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "/marketing/automation" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Automation")}
                    </Link>
                    <Link
                      to="/campaign"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "/campaign" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Campaigns")}
                    </Link>
                    <Link
                      to="/marketing/landing-pages"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "/marketing/landing-pages" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Landing Pages")}
                    </Link>{" "}
                    <Link
                      to="/marketing/forms"
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        "/marketing/forms" === pathName ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale("pages:navigation.Forms")}
                    </Link>
                    {showMenuItems("social_media") && (
                      <NavDropdown
                        title={locale("pages:navigation.Social Media")}
                        id="submenu-nav-dropdown"
                        className={
                          urlSchema["social"].indexOf(pathName) > -1
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to="/social-media/add-account"
                          state={{ industry: industry }}
                          className={`dropdown-item ${
                            ["social-media/add-account"].indexOf(pathName) > -1
                              ? "active"
                              : ""
                          }`}
                          role="button"
                          onClick={() => navigationDrawerHide()}
                        >
                          {locale("pages:navigation.Add Account")}
                        </Link>
                        <Link
                          to="/social-media/social-post"
                          state={{ industry: industry }}
                          className={`dropdown-item ${
                            ["social-media/social-post"].indexOf(pathName) > -1
                              ? "active"
                              : ""
                          }`}
                          role="button"
                          onClick={() => navigationDrawerHide()}
                        >
                          {locale("pages:navigation.Schedule Posts")}
                        </Link>
                        <Link
                          to="/social-media/monitoring"
                          state={{ industry: industry }}
                          className={`dropdown-item ${
                            ["social-media/monitoring"].indexOf(pathName) > -1
                              ? "active"
                              : ""
                          }`}
                          role="button"
                          onClick={() => navigationDrawerHide()}
                        >
                          {locale("pages:navigation.Monitor Accounts")}
                        </Link>
                      </NavDropdown>
                    )}
                  </NavDropdown>
                )}
              </React.Fragment>
            )}
          </Nav>
        </Navbar.Collapse>{" "}
      </Navbar>

      <AnnouncementPreview
        show={showAnnouncements}
        handleClose={handleClose}
        currentCompanySetting={currentCompanySetting}
        announcements={announcements}
      />
    </React.Fragment>
  );
};

export default Navigation;
