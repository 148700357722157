export const getExtensionFromMimeType = (mimeType) => {
    const mimeTypes = {
        'application/epub+zip': 'epub',
        'application/gzip': 'gz',
        'application/java-archive': 'jar',
        'application/json': 'json',
        'application/msword': 'doc',
        'application/pdf': 'pdf',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.oasis.opendocument.text': 'odt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/x-7z-compressed': '7z',
        'application/x-bzip2': 'bz2',
        'application/x-rar-compressed': 'rar',
        'application/x-tar': 'tar',
        'application/xml': 'xml',
        'application/zip': 'zip',
        'audio/aac': 'aac',
        'audio/midi': 'midi',
        'audio/mpeg': 'mp3',
        'audio/ogg': 'oga',
        'audio/wav': 'wav',
        'audio/webm': 'weba',
        'font/otf': 'otf',
        'font/ttf': 'ttf',
        'font/woff': 'woff',
        'font/woff2': 'woff2',
        'image/bmp': 'bmp',
        'image/gif': 'gif',
        'image/jpeg': 'jpeg',
        'image/png': 'png',
        'image/svg+xml': 'svg',
        'image/tiff': 'tiff',
        'image/webp': 'webp',
        'text/css': 'css',
        'text/csv': 'csv',
        'text/html': 'html',
        'text/javascript': 'js',
        'text/plain': 'txt',
        'text/xml': 'xml',
        'video/3gpp': '3gp',
        'video/mp2t': 'ts',
        'video/mp4': 'mp4',
        'video/mpeg': 'mpeg',
        'video/ogg': 'ogv',
        'video/webm': 'webm',
        // Add more mappings as needed
    };

    const mimeTypeNormalized = mimeType.toLowerCase();
    return mimeTypes[mimeTypeNormalized] || '';
}
