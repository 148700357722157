import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { API_URL } from "../Utils/utils";
import Pusher from "pusher-js";
import PusherLink from "./PusherLink";
import { ApolloLink } from "@apollo/client/core";
import { getAuthToken, getCsrfToken } from "./tokenUtils";
import Cookies from "js-cookie";

const isLocalOnline = process.env.REACT_APP_ENV === 'local-online';

// Create the upload link
const uploadLink = createUploadLink({
  uri: API_URL + "/graphql",
  credentials: isLocalOnline ? 'same-origin' : 'include',
});

// Authorization logic using both cookies and localStorage
const authLink = setContext(async (_, { headers }) => {
  if (window.location !== window.parent.location) {
    // If in an iframe, skip adding authorization
    return {
      headers: {
        ...headers,
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
      },
    };
  } else {
    if (isLocalOnline) {
      // Token-based authentication
      const token = getAuthToken(); // Retrieve token from storage
      if (!token) {
        throw new Error("No authentication token found. Please login.");
      }
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json',
        },
      };
    } else {
      // Session-based authentication
      await getCsrfToken(); // Ensure CSRF token is loaded
      const xsrfToken = Cookies.get('XSRF-TOKEN');
      return {
        headers: {
          ...headers,
          'X-XSRF-TOKEN': xsrfToken ? decodeURIComponent(xsrfToken) : undefined,
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json',
        },
      };
    }
  }
});

const pusherLink = new PusherLink({
  pusher: new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    authEndpoint: `${process.env.REACT_APP_BACKEND_URL}/graphql/subscriptions/auth`,
    auth: {
      headers: isLocalOnline
        ? {
            Authorization: `Bearer ${getAuthToken()}`,
          }
        : {
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
          },
    },
  }),
});

// Combine the links in the correct order
const link = ApolloLink.from([authLink, pusherLink, uploadLink]);

// Create the Apollo Client instance
const graphqlClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
// const graphqlClient = new ApolloClient({
//   uri: API_URL + "/graphql",
//   cache: new InMemoryCache(),
//   headers: {
//     authorization: apiToken ? `Bearer ${apiToken}` : "",
//   },
// });
export default graphqlClient;
