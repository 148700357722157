import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'remixicon/fonts/remixicon.css';
import './Assets/Styles/style.scss';
import './Assets/Styles/responsive.scss';

import ParentContext from './Context/MainContext';
import PipelineContextProvider from './Context/PipelineContext';
import Layouts from './Layouts/Layouts';
import DuplicateContactsContextProvider from './Context/DuplicateContactsContext';
import UnverifiedContactsContextProvider from './Context/UnverifiedContactContext';

//...
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV === 'production')
  Sentry.init({
    dsn: "https://e01d861e35be8fd8cc81a48cc23176db@o4508058125205504.ingest.us.sentry.io/4508059710980096",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const App = () => (
  <ParentContext>
    <PipelineContextProvider>
      <DuplicateContactsContextProvider>
        <UnverifiedContactsContextProvider>
          <Router>
            {/* Need to change layout name at the time of ui implementation */}
            <Layouts />
          </Router>
        </UnverifiedContactsContextProvider>
      </DuplicateContactsContextProvider>
    </PipelineContextProvider>
  </ParentContext>
);

export default App;
