export const equpIndustryInfo = {
  ecommerce: false,
  services: false,
  subscriptions: false,
};
export const lifeCycleStage = [
  {
    label: "Contact",
    value: "contact",
  },
  {
    label: "Lead",
    value: "lead",
  },
  {
    label: "Prospect",
    value: "prospect",
  },
  {
    label: "Customer",
    value: "customer",
  },
];

export const industryWiseName = {
  saas: "Plans",
  service: "Offerings",
  services: "Offerings",
  ecommerce: "Products",
};
export const addressParts = ["address", "city", "state", "country", "zip"];
export const socialHandler = [
  "facebook",
  "twitter",
  "google",
  "linkedin",
  "skype",
];

export const acceptableType = (type) => {
  switch (type) {
    case "image":
      return {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      };
    case "excel":
      return {
        "text/csv": [".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      };
    case "documents":
      return {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        "text/plain": [".txt"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
      };
    case "video":
      return {
        "video/*": [".mp4", ".mpeg", ".ogv", ".webm", ".3gp"],
      };
    case "audio":
      return {
        "audio/*": [".mp3", ".mp4", ".amr", ".ogg"],
      };
    case "document":
      return {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        "text/csv": [".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
      };
    default:
      return {};
  }
};

export const weeksDays = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

export const daysByNumber = Array.from({ length: 31 }, (v, i) => {
  const day = i + 1;
  let suffix;

  if (day % 10 === 1 && day !== 11) {
    suffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    suffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    suffix = "rd";
  } else {
    suffix = "th";
  }
  return { value: day, label: `${day}${suffix}` };
});

export const monthByName = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const durationType = { 1: "weeks", 2: "Months", 5: "Years" };
export const repeatJobTypes = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "Monthly" },
  //   { value: 3, label: 'Quarterly' },
  //   { value: 4, label: 'Bi-Yearly' },
  { value: 5, label: "Yearly" },
];
export const repeatCycleTypes = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Yearly" },
];
export const billingCycles = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Yearly" },
];

export const repeatJobOptions = {
  1: weeksDays,
  2: daysByNumber,
  5: monthByName,
};

export const errorStyle = {
  border: "1px solid #f00",
  borderRadius: "3px",
  paddingRight: "1px",
};

export const JOB_FORM_OPTION = [
  { value: 0, label: "Technician" },
  { value: 1, label: "Client" },
];
export const JOB_FORM_FOR = { 0: "USER", 1: "CONTACT" };

export const FORM_FIELDS = {
  1: "TEXTBOX",
  2: "TEXTAREA",
  3: "CHECKBOX",
  4: "RADIO",
  5: "DATE",
  6: "UPLOAD",
  7: "DROPDOWN",
};

export const jobPriorites = [
  { value: "0", label: "ASAP" },
  { value: "1", label: "Low" },
  { value: "2", label: "Medium" },
  { value: "3", label: "High" },
];

export const JOB_FORM_TYPE = {
  0: "CHECKLIST",
  1: "INSPECTION",
  2: "SURVEY",
};

export const fieldTypes = {
  text: "text",
  integer: "number",
  decimal: "text",
  date: "date",
  time: "time",
  percentage: "number",
  email: "email",
  phone: "text",
  url: "text",
  textarea: "textarea",
  dropdown: "select",
  checkbox: "checkbox",
  radio_button: "radio",
  consent: "checkbox",
  upload: "upload",
  radio: "radio",
};

export const defaultQuestion = {
  question: null,
  answer_type: null,
  default_value: null,
  item_options: null,
  item_attributes: null,
  required: false,
};

const availableTypes = {
  text: "textbox",
  date: "date",
  textarea: "textarea",
  dropdown: "select",
  checkbox: "checkbox",
  radio_button: "radio",
  upload: "upload",
};

export const fontList = [
  { value: "Open Sans, sans-serif", label: "Open Sans, Sans-Serif" },
  { value: "Arial, Helvetica, sans-serif", label: "Arial" },
  { value: "Lucida Sans Unicode", label: "Lucida Grande" },
  { value: "Palatino Linotype", label: "Times New Roman" },
  { value: "Georgia, serif", label: "Georgia, serif" },
  { value: "Tahoma, Geneva, sans-serif", label: "Tahoma" },
  { value: "Comic Sans MS, cursive, sans-serif", label: "Comic Sans" },
  { value: "Verdana, Geneva, sans-serif", label: "Verdana" },
  { value: "Impact, Charcoal, sans-serif", label: "Impact" },
  { value: "Arial Black, Gadget, sans-serif", label: "Arial Black" },
  { value: "Trebuchet MS, Helvetica, sans-serif", label: "Trebuchet" },
  {
    value: "Courier New, Courier, monospace",
    label: "Courier New, Courier, monospace",
  },
  { value: "Brush Script MT, sans-serif", label: "Brush Script" },
  {
    value:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
    label: "System Default",
  },
  { value: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif", label: "Segoe UI" },
  { value: "Roboto, Helvetica, Arial, sans-serif", label: "Roboto" },
  {
    value: "San Francisco, -apple-system, BlinkMacSystemFont",
    label: "San Francisco (macOS)",
  },
  { value: "Cantarell, Arial, sans-serif", label: "Cantarell (GNOME)" },
];

export const fontSizes = (min, max) => {
  const myFontSizes = [];
  for (let i = min; i <= max; i++) {
    const size = `${i}px`;
    myFontSizes.push({ value: size, label: size });
  }
  return myFontSizes;
};

export const otherFieldsType = {
  date_of_birth: "date",
  number: "number",
  percentage: "number",
  decimal: "number",
  url: "text",
  radio_button: "radio",
  phone: "phone",
  mobile: "phone",
  landline: "phone",
  fax: "phone",
  dropdown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
};

export const globalEvents = [
  {
    value: "date_time",
    label: "Date Time",
  },
  {
    value: "dob",
    label: "DOB",
  },
  {
    value: "newyear",
    label: "Newyear",
  },
  {
    value: "valentines_day",
    label: "Valentines Day",
  },
  {
    value: "easter",
    label: "Easter",
  },
  {
    value: "woman_s_day",
    label: "Women's Day",
  },
  {
    value: "thanks_giving",
    label: "Thanks Giving",
  },
  {
    value: "halloween",
    label: "Halloween",
  },
  {
    value: "christmas",
    label: "Christmas",
  },
  {
    value: "every_day_of_week",
    label: "Every Day Of Week",
  },
  {
    value: "every_day_of_month",
    label: "Every Day Of Month",
  },
];

export const conditionObj = {
  attribute: null,
  attribute_label: null,

  operator: null,
  operator_label: null,

  first_value: null,
  first_value_label: null,

  second_value: null,
  second_value_label: null,

  attribute_id: null,
  attribute_id_label: null,
  condition: "and",
};
export const alphabets = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};
