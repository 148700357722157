import { useLazyQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { FSP_SUBSCRIBED_PLAN } from "../GraphApi/EqupPlans/Plan";
import { currentTeamId, hasClient, publicRoutesName } from "../Utils/utils";
import { AuthContext } from "./AuthContext";
import {
  currentDateObject,
  getDateformat,
  getDateformatWise,
} from "../Utils/EqupTimezone";

const initialState = {
  isFetching: false,
  planData: null,
  isPlanExpired: false,
  error: null,
};

export const TrialPlanChecker = createContext(null);

const checkIfPlanExpired = (plan) => {
  const currentDate = getDateformatWise(
    currentDateObject().utc(),
    "YYYY-MM-DD HH:mm:ss"
  );

  if (plan) {
    const planExpireDate = plan.trial_expiry_date
      ? plan.trial_expiry_date
      : plan.end_at;

    const endDate = getDateformat(planExpireDate).utc();
    const remainingDays = endDate < currentDate;
    return remainingDays;
  } else {
    return true;
  }
};

function PlanReducer(state, action) {
  switch (action.type) {
    case "PLAN_REQUEST":
      return { ...state, isFetching: true };
    case "PLAN_SUCCESS":
      return {
        ...state,
        isFetching: false,
        planData: action.payload,
        isPlanExpired:
          checkIfPlanExpired(action.payload) || !action.payload.status,
        error: null,
      };
    case "PLAN_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const TrialPlanCheckerProvider = ({ children, pathname }) => {
  const { userData } = useContext(AuthContext);
  const [state, dispatch] = useReducer(PlanReducer, initialState, undefined);
  const [getSubscribedPlan] = useLazyQuery(FSP_SUBSCRIBED_PLAN, {
    fetchPolicy: "network-only",
  });

  const isMethodCallable = () => {
    if (
      !isNaN(currentTeamId) &&
      currentTeamId &&
      userData.isFetching === false &&
      userData.authenticated &&
      !hasClient(userData) &&
      ["/client-dashboard", ...publicRoutesName].filter(
        (item) => window.location.pathname.indexOf(item) > -1
      ).length === 0
    ) {
      return true;
      // return false;
    }
    return false;
  };

  useEffect(() => {
    if (isMethodCallable()) {
      dispatch({ type: "PLAN_REQUEST" });
      fetchSubscribedPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  const fetchSubscribedPlan = () => {
    getSubscribedPlan({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        dispatch({
          type: "PLAN_SUCCESS",
          payload: result.data.getSubscribedPlan,
        });
      })
      .catch((error) => {
        console.log("Error:", error);
        dispatch({ type: "PLAN_FAILURE", payload: error.response });
      });
  };

  const value = {
    isPlanExpired: state.isPlanExpired,
    planData: state.planData,
    isFetching: state.isFetching,
    error: state.error,
    fetchSubscription: () => {
      // console.log("isMethodCallable: ",isMethodCallable());
      if (isMethodCallable()) {
        dispatch({ type: "PLAN_REQUEST" });
        fetchSubscribedPlan();
      }
    },
  };

  return (
    <TrialPlanChecker.Provider value={value}>
      {children}
    </TrialPlanChecker.Provider>
  );
};
