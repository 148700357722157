import axios from "axios";
import { getAuthToken, getCsrfToken, resetCsrfToken } from './tokenUtils'; // Centralized token logic
import { API_URL, hasAffiliate } from "../Utils/utils";
import Cookies from "js-cookie";

const isLocalOnline = process.env.REACT_APP_ENV === 'local-online';

export const RestApi = axios.create({
  baseURL: API_URL,
  withCredentials: !isLocalOnline,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

// Interceptor for including authentication and CSRF tokens
RestApi.interceptors.request.use(
  async (config) => {
    
    const bypassTokenEndpoints = ["/login", "/register", "/pin-login"];
    const isBypassEndpoint = bypassTokenEndpoints.some(endpoint => config.url.includes(endpoint));

    if (isLocalOnline && isBypassEndpoint) {
      return config; // Allow certain requests to bypass token checks
    }

    if (isLocalOnline) {
      const authToken = getAuthToken(); // Centralized token retrieval
      if (!authToken) {
        throw new Error("No authentication token found. Please login.");
      }
      config.headers['Authorization'] = `Bearer ${authToken}`;
    } else {
      await getCsrfToken(); // Centralized CSRF token logic
      const xsrfToken = Cookies.get('XSRF-TOKEN');
      if (xsrfToken) {
        config.headers['X-XSRF-TOKEN'] = decodeURIComponent(xsrfToken);
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Removed the Bearer token logic as we're using session-based auth now
export const getIdToken = async () => {
  // Something you want to perform before each request...
  // otherwise we can remove this function and its references...
};

// Export your REST functions (register, login, etc.) as before
export function register(data) {
  return RestApi.post("/register", data);
}

// creates a fresh account for user from website...
export function paidRegister(data) {
  // return RestApi.post("/set-my-password", data);
  return RestApi.post("register", data);
}

// returns current loggedin user details...
export async function getUser() {
  await getIdToken();
  if (!hasAffiliate()) return await RestApi.get("/api/me");
}

// To logout a user...
export function logout() {
  return RestApi.post("/logout", {});
}

// to login a user to dashboard...
export function login(data) {
  return RestApi.post("/login", data);
}
// to login with a pin and send user to dashboard...
export function pinLogin(data) {
  return RestApi.post("/pin-login", data);
}

// get access token for linkedin
export function getLinkedInToken(data, type = "linkedin") {
  let url = "http://127.0.0.1:8000/api/linkedin-token";
  let request_url = "https://www.linkedin.com/oauth/v2/accessToken";
  // if (type === "twitter") {
  //   url = "http://127.0.0.1:8000/api/twitter-token";
  //   request_url = "https://api.twitter.com/2/oauth2/token";
  // }
  // const url = "/api/linkedin-auth";
  const dataToBeSent = {
    data: data,
    request_url: request_url,
  };
  return RestApi.post(url, dataToBeSent);
}

// Social login
export function socialLogin(data) {
  return RestApi.post("/social-login", data);
}

// Social login
export async function globalSearch(data) {
  await getIdToken();
  return RestApi.post("/api/g-search", data);
}

// to login a Affiliate to dashboard...
export function affiliateLogin(data) {
  return RestApi.post("/affiliate/login", data);
}

export async function getCallToken(data) {
  await getIdToken();
  return RestApi.post("/api/call/token", data);
}
/****Social MEdia****/
export async function twitterRequestToken(data) {
  await getIdToken();
  // const data = { redirect_uri: redirectUrl };
  return RestApi.post("/api/twitter-request-token", data);
}
export async function twitterAccessToken(data) {
  await getIdToken();
  return RestApi.post("/api/twitter-access-token", data);
}
export async function getChannelAccessToken(data, channel) {
  await getIdToken();
  if (channel === "facebook") {
    return RestApi.post("/api/facebook-token", data);
  } else if (channel === "twitter") {
    return RestApi.post("/api/twitter-token", data);
  } else if (channel === "linkedin") {
    let request_url = "https://www.linkedin.com/oauth/v2/accessToken";
    const dataToBeSent = {
      data: data,
      request_url: request_url,
    };
    return RestApi.post("/api/linkedin-token", dataToBeSent);
  }
}

// tracking script
export function startTracking(data) {
  return RestApi.get("/saleoidTracker", {
    params: data,
  });
}

// tracking script
export function updateTracking(data) {
  return RestApi.get("/jsonCloseCallback", {
    params: data,
  });
}

//Upload Temporary File.
export async function getFileUploadRequest(data = null, operation = null) {
  const url = "/api/upload-temp-document";
  return await RestApi.post(url, { ...data, ba_type: "AppModelsTeamUser" });
}
