import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getFirstCharacter,
  isObjectEmpty,
  toWordCase,
  userAvatar,
} from "../../../Utils/utils";
import { SearchResultLoading } from "../Loader/SkeletonLoader";

export const SearchListing = ({
  searchData,
  handleSearch,
  industry,
  loading,
  setHandleSearch,
  allowedPages,
  setOnPage,
}) => {
  const { t: locale } = useTranslation(["common"]);
  if (loading) {
    return (
      <div className={"global-search-dropdown active"}>
        <SearchResultLoading />
      </div>
    );
  }
  return (
    <div
      className={
        handleSearch && searchData !== null
          ? "global-search-dropdown active"
          : "global-search-dropdown"
      }
    >
      {isObjectEmpty(searchData) || searchData.length === 0 ? (
        <div className="results-not-found">
          <div>
            <img src={require("../../../Assets/Images/not-found.png")} alt="" />
            <p>{locale("Results Not Found")}</p>
          </div>
        </div>
      ) : (
        <div className="global-search-dropdown-inner">
          {searchData.map((item, idx) => (
            <React.Fragment key={item.id}>
              <Link
                className="global-search-list"
                to="/contact-details"
                state={{ id: item.id, industry: industry }}
                role="button"
                key={item.id + "-" + idx}
                onClick={() => {
                  setTimeout(() => {
                    setHandleSearch(false);
                  }, 500);
                }}
              >
                <SearchedItem item={item} />
              </Link>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export const SearchedItem = ({ item }) => {
  return (
    <React.Fragment>
      <div className="search-img">
        <div className="search-avtar">
          {/* {item.contact_image ? ( */}
          <img src={item.contact_image || userAvatar} alt="Avatar" />

          {/* <div className="small-avtar">
            {getFirstCharacter(item.name ? toWordCase(item.name.trim()) : "")}
          </div> */}
        </div>
      </div>
      <div className="search-details">
        <h5>{item.name}</h5>
        <h6>{item.primaryName}</h6>
        <p>
          <i className={"ri-mail-line"}></i> {item.email} {item.address}
        </p>
        {item.company_name && (
          <p style={{ fontWeight: "bold" }}>
            <i className={"ri-building-line"}></i> {item.company_name}
          </p>
        )}
        <span className="search-type">
          <i className={"ri-user-line"}></i>
        </span>
      </div>
    </React.Fragment>
  );
};
