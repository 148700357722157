import Cookies from 'js-cookie';
import axios from 'axios';
import { API_URL } from '../Utils/utils';

// Key names for tokens
const AUTH_TOKEN_KEY = 'AUTH-TOKEN';
const SESSION_TOKEN_KEY = 'AUTH-TOKEN';
const CSRF_TOKEN_KEY = 'XSRF-TOKEN';

let csrfTokenLoaded = false;
// --- AUTH TOKEN LOGIC ---

// Get the auth token from either session storage or cookies
export const getAuthToken = () => {
  // Try to get the token from session storage
  let token = sessionStorage.getItem(SESSION_TOKEN_KEY);

  // If it's not found in session storage, get it from the cookie
  if (!token) {
    token = Cookies.get(AUTH_TOKEN_KEY);
  }

  return token;
};

// Set the auth token in both session storage and cookies
export const setAuthToken = (token) => {
  // Store in session storage
  sessionStorage.setItem(SESSION_TOKEN_KEY, token);

  // Store in cookies (7-day expiration, can be adjusted)
  Cookies.set(AUTH_TOKEN_KEY, token, { expires: 7 });
};

// Remove the auth token from both session storage and cookies (for logout)
export const removeAuthToken = () => {
  // Remove from session storage
  sessionStorage.removeItem(SESSION_TOKEN_KEY);

  // Remove from cookies
  Cookies.remove(AUTH_TOKEN_KEY);
};

// --- CSRF TOKEN LOGIC ---

// Get the CSRF token (ensure it is loaded if needed)
export const getCsrfToken = async () => {
  const isLocalOnline = process.env.REACT_APP_ENV === 'local-online';

  if (isLocalOnline) {
    const authToken = getAuthToken();
    if (!authToken) {
      throw new Error("No authentication token found. Please login.");
    }
    return; // No CSRF needed in local-online
  }

  if (!csrfTokenLoaded) {
    try {
      await axios.get(`${API_URL}/sanctum/csrf-cookie`, { withCredentials: true });
      csrfTokenLoaded = true;
    } catch (error) {
      console.error("Failed to load CSRF token", error);
      csrfTokenLoaded = false;
    }
  }
};

// Reset the CSRF token by marking it as unloaded
export const resetCsrfToken = async () => {
  csrfTokenLoaded = false;
  await getCsrfToken();
};

// Optional: Function to refresh the auth token if using refresh tokens
export const refreshAuthToken = async () => {
  try {
    const response = await axios.post(`${API_URL}/auth/refresh`, {}, { withCredentials: true });
    if (response.data && response.data.authToken) {
      setAuthToken(response.data.authToken);
    }
  } catch (error) {
    console.error("Failed to refresh auth token", error);
  }
};
