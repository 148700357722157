import React from "react";
import PropTypes from "prop-types";
import { createMarkup } from "../../../Utils/utils";

const getNestedValue = (obj, path) => {
  const keys = path.replace(/\[(\d+)\]/g, ".$1").split(".");
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  );
};

const alertType = {
  error: { variant: "text-danger", icon: "ri-alert-line", title: "Error!" },
  success: {
    variant: "text-success",
    icon: "ri-checkbox-circle-line",
    title: "Success",
  },
  warning: {
    variant: "text-warning",
    icon: "ri-alert-line",
    title: "Warning!",
  },
};

const style = {
  position: "absolute",
};

const InLineErrorMessage = ({
  type,
  name,
  formik = {},
  id = "",
  extraStyle = {},
  specialPermissions = false,
  touched = false,
}) => {
  /** Style **/
  if (specialPermissions) {
    return (
      <React.Fragment>
        <span
          className={"small " + alertType[type].variant}
          style={{ ...style, ...extraStyle }}
          id={id ? id : ""}
        >
          {createMarkup(
            touched && formik && formik.errors[name]
              ? typeof getNestedValue(formik.errors, name) === "object"
                ? getNestedValue(formik.errors, name)?.value
                : getNestedValue(formik.errors, name)
              : "",
            "emoji"
          )}
        </span>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <span
          className={"small " + alertType[type].variant}
          style={{ ...style, ...extraStyle }}
          id={id ? id : name}
        >
          {createMarkup(
            formik &&
              getNestedValue(formik.touched, name) &&
              getNestedValue(formik.errors, name)
              ? typeof getNestedValue(formik.errors, name) === "object"
                ? getNestedValue(formik.errors, name)?.value
                : getNestedValue(formik.errors, name)
              : "",
            "emoji"
          )}
        </span>
      </React.Fragment>
    );
  }
};

InLineErrorMessage.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  id: PropTypes.string,
  extraStyle: PropTypes.object,
  specialPermissions: PropTypes.bool,
  touched: PropTypes.bool,
};

export default InLineErrorMessage;
